<template>
  <div class="calendar">
    <div class="calTop">
      <span class="calTopTitle">按照公布日期查询</span>
      <div class="positionPic">
        <el-date-picker
          v-model="datePicker"
          type="date"
          suffix-icon="el-icon-date"
          placeholder="选择查询日期"
          value-format="yyyy-MM-dd"
          style="width:280px;height:40px;border-radius: 15px;"
          prefix-icon="prefix-icon-class"
          @change="selectPicker">
        </el-date-picker>
        <i class="iconfont icon-riqi"></i>
      </div>
      <div class="shaixuanBox">
        <span>筛选</span>
        <i class="iconfont icon-shaixuan"></i>
        <ul class="shaixuanCenter">
          <li>
            <span>状态</span>
            <el-row>
              <el-button @click="clickBtn(1,$event)" :class="status == 1 ? 'statusBtn' : '' " type="info" size="small">全部</el-button>
              <el-button @click="clickBtn(2,$event)" :class="status == 2 ? 'statusBtn' : '' " type="info" size="small">今日</el-button>
              <el-button @click="clickBtn(3,$event)" :class="status == 3 ? 'statusBtn' : '' " type="info" size="small">未知</el-button>
              <el-button @click="clickBtn(4,$event)" :class="status == 4 ? 'statusBtn' : '' " type="info" size="small">重要</el-button>
            </el-row>
          </li>
          <li>按照国家/地区条件查找</li>
          <li>
            <p>美洲</p>
            <div>
              <el-row>
                <el-button @click="clickBtn2(1,$event)" :class="local == 1 ? 'statusBtn' : '' " type="info" size="small">全部</el-button>
              </el-row>
            </div>
          </li>
          <li>
            <p>欧元</p>
            <div>
              <el-row>
                <el-button @click="clickBtn2(2,$event)" :class="local == 2 ? 'statusBtn' : '' " type="info" size="small">德国</el-button>
                <el-button @click="clickBtn2(3,$event)" :class="local == 3 ? 'statusBtn' : '' " type="info" size="small">英国</el-button>
                <el-button @click="clickBtn2(4,$event)" :class="local == 4 ? 'statusBtn' : '' " type="info" size="small">法国</el-button>
                <el-button @click="clickBtn2(5,$event)" :class="local == 5 ? 'statusBtn' : '' " type="info" size="small">瑞士</el-button>
              </el-row>
              <el-row>
                <el-button @click="clickBtn2(6,$event)" :class="local == 6 ? 'statusBtn' : '' " type="info" size="small">意大利</el-button>
                <el-button @click="clickBtn2(7,$event)" :class="local == 7 ? 'statusBtn' : '' " type="info" size="small">西班牙</el-button>
              </el-row>
            </div>
          </li>
          <li>
            <p>美洲</p>
            <div>
              <el-row>
                <el-button @click="clickBtn2(8,$event)" :class="local == 8 ? 'statusBtn' : '' " type="info" size="small">意大利</el-button>
                <el-button @click="clickBtn2(9,$event)" :class="local == 9 ? 'statusBtn' : '' " type="info" size="small">西班牙</el-button>
              </el-row>
            </div>
          </li>
          <li>
            <p>亚洲</p>
            <div>
              <el-row>
                <el-button @click="clickBtn2(10,$event)" :class="local == 10 ? 'statusBtn' : '' " type="info" size="small">中国</el-button>
                <el-button @click="clickBtn2(11,$event)" :class="local == 11 ? 'statusBtn' : '' " type="info" size="small">日本</el-button>
                <el-button @click="clickBtn2(12,$event)" :class="local == 12 ? 'statusBtn' : '' " type="info" size="small">韩国</el-button>
                <el-button @click="clickBtn2(13,$event)" :class="local == 13 ? 'statusBtn' : '' " type="info" size="small">印度</el-button>
              </el-row>
              <el-row>
                <el-button @click="clickBtn2(14,$event)" :class="local == 14 ? 'statusBtn' : '' " type="info" size="small">香港</el-button>
                <el-button @click="clickBtn2(15,$event)" :class="local == 15 ? 'statusBtn' : '' " type="info" size="small">台湾</el-button>
                <el-button @click="clickBtn2(16,$event)" :class="local == 16 ? 'statusBtn' : '' " type="info" size="small">新加坡</el-button>
              </el-row>
            </div>
          </li>
          <li>
            <p>澳洲</p>
            <div>
              <el-row>
                <el-button @click="clickBtn2(17,$event)" :class="local == 17 ? 'statusBtn' : '' " type="info" size="small">澳大利亚</el-button>
                <el-button @click="clickBtn2(18,$event)" :class="local == 18 ? 'statusBtn' : '' " type="info" size="small">意大利</el-button>
                <el-button @click="clickBtn2(19,$event)" :class="local == 19 ? 'statusBtn' : '' " type="info" size="small">西班牙</el-button>
              </el-row>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="inBoxLeft">
      <div class="leftTop">
        <div></div>
        <ul>
          <li @click="tabClick(0,$event)" :class="controlDiv === 0 ? 'liStyle' : '' ">
            <p @click="pclick($event)">
              {{weekDate[0]}}<br/>
              周一
            </p>
          </li>
          <li @click="tabClick(1)" :class="controlDiv === 1 ? 'liStyle' : '' ">
           <p @click="pclick($event)">
              {{weekDate[1]}}<br/>
              周二
            </p>
          </li>
          <li @click="tabClick(2)" :class="controlDiv === 2 ? 'liStyle' : '' ">
            <p @click="pclick($event)">
              {{weekDate[2]}}<br/>
              周三
            </p>
          </li>
          <li @click="tabClick(3)" :class="controlDiv === 3 ? 'liStyle' : '' ">
            <p @click="pclick($event)">
              {{weekDate[3]}}<br/>
              周四
            </p>
          </li>
          <li @click="tabClick(4)" :class="controlDiv === 4 ? 'liStyle' : '' ">
            <p @click="pclick($event)">
              {{weekDate[4]}}<br/>
              周五
            </p>
          </li>
          <li @click="tabClick(5)" :class="controlDiv === 5 ? 'liStyle' : '' ">
            <p @click="pclick($event)">
              {{weekDate[5]}}<br/>
              周六
            </p>
          </li>
          <li @click="tabClick(6)" :class="controlDiv === 6 ? 'liStyle' : '' ">
            <p @click="pclick($event)">
              {{weekDate[6]}}<br/>
              周天
            </p>
          </li>
        </ul>
      </div>
      <div class="leftBottom">
        <el-table
          :data="tableData"
          v-loading = 'loading'
          style="width: 100%"
          :header-cell-style="{textAlign: 'center',color:'#999999',height:'65px'}"
          :row-style="{height:'65px'}"
          :cell-style="{ textAlign: 'center',color:'#959595'}">
          <el-table-column
            prop="Time"
            label="时间">
          </el-table-column>
          <el-table-column
            prop="CountryUrl"
            label="国/地区">
            <template slot-scope="scope">
              <img :src="scope.row.CountryUrl" alt="">
            </template>
          </el-table-column>
          <el-table-column
            prop="CalendarName"
            label="事件名称"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="Importance"
            label="重要性">
            <template slot-scope="scope">
              <div v-if="scope.row.Importance == 1">
                <i style="color:#ffad39" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 2">
                <i style="color:#ffad39" class="iconfont icon-shoucang"></i>
                <i style="color:#ffad39" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 3">
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 4">
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i class="iconfont icon-shoucang"></i>
              </div>
              <div v-if="scope.row.Importance == 5">
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
                <i style="color:red" class="iconfont icon-shoucang"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Before_value"
            label="前值">
            <template slot-scope="scope">
              <span v-if="scope.row.Before_value =='' ">--</span>
              <span v-else>{{scope.row.Before_value}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ForecastValue"
            label="预测值">
            <template slot-scope="scope">
              <span v-if="scope.row.ForecastValue =='' ">--</span>
              <span v-else>{{scope.row.ForecastValue}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="PublicValue"
            label="公布值">
            <template slot-scope="scope">
              <span v-if="scope.row.PublicValue =='' ">未公布</span>
              <span v-else>{{scope.row.PublicValue}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Influence"
            label="影响">
            <template slot-scope="scope">
              <span v-if="scope.row.Influence =='' || scope.row.Influence =='未公布' ">--</span>
              <span style="border-color:#ff9106;color:#ff9106" class="Influence" v-if="scope.row.Influence =='影响较小'">{{scope.row.Influence}}</span>
              <span style="border-color:#1bbe60;color:#1bbe60" class="Influence" v-if="scope.row.Influence =='利多 金银'">{{scope.row.Influence}}</span>
              <span style="border-color:#ff4d53;color:#ff4d53" class="Influence" v-if="scope.row.Influence =='利空 金银'">{{scope.row.Influence}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="button" v-if="controlBtn" @click="moreCalendar">
        <button>
          查看更多
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {getHeadlineNewsApi,getViewListWithHolidayApi} from '../../../api/info'
export default {
  name:'calendar',
  data(){
    return{
      // 控制leftTop
      controlDiv:1,
      // 表格数据
      tableData:[],
      // 该日所在周的所有日期
      weekDate:[],
      // 筛选的日期
      datePicker:'',
      // 状态筛选按钮
      status:1,
      // 地区筛选按钮
      local:1,
      loading:true,
      // 控制是否显示btn
      controlBtn:false,
      // 调取参数的接口
      attr:{
        date:(new Date()).Format('yyyyMMdd'),
        type:-1,
        country: '',
        importance: '',
      }
    }
  },
  created(){
    this.initViewList(this.attr,0);
    // 默认日期选择框为今天日期
    this.datePicker = (new Date()).Format('yyyy-MM-dd');
    // 通过默认日期查询出该日所在周的所有日期，以及控制controlDiv的显示
    this.selectPicker(this.datePicker);
  },
   mounted () {
    // 修改日期选择器的图标位置
    // document.getElementsByClassName('el-input__icon')[1].classList.remove('el-range__close-icon')
    // document.getElementsByClassName('el-input__icon')[1].classList.add('el-icon-date')
  },
  methods:{
    // 获取财经日历方法
    initViewList(attr,type){
      getViewListWithHolidayApi(attr).then(res=>{
        this.controlBtn = false;
        if(res.Code == 0){
          this.loading = false;
          let data = res.Result.filter((item)=>{
            if(item.Before_value!==''){
              return item;
            }
          });
          if(type == 0){
            this.tableData = data.splice(0,7);
            if(data.length>7){
              this.controlBtn = true;
            }
          }else{
            this.tableData = data;
            this.controlBtn = false;
          }
        }
      })
    },
    // tabbar点击事件
    tabClick(attr,event){
      // console.log(event.target);
      this.controlDiv = attr;
    },
    // 获取点击tab的日期，赋值给datepicker
    pclick(event){
      this.loading = true;
      this.attr.country = '';
      this.attr.importance = '';
      // 截取点击后的具体时间
      this.datePicker = (event.target.innerHTML.split('<')[0].replace(/\s*/g,"")).replace(/-/g,"");
      this.attr.date = this.datePicker;
      this.initViewList(this.attr,0);
    },
    // 按状态筛选
    clickBtn(key,event){
      this.loading = true;
      this.attr.country = '';
      if(event.target.innerHTML.length>5){
        console.log((event.target.innerHTML.split('</')[0]).split('n>')[1]);
        switch((event.target.innerHTML.split('</')[0]).split('n>')[1]){
          case '全部':
            this.attr.importance = '';
            break
          case '今日':
            this.attr.importance = '';
            break
          case '未知':
            this.attr.importance = 0;
            break
          case '重要':
            this.attr.importance = 2;
            break
        }
        this.initViewList(this.attr,0);
      }else{
        switch(event.target.innerHTML){
          case '全部':
            this.attr.importance = '';
            break
          case '今日':
            this.attr.importance = '';
            break
          case '未知':
            this.attr.importance = 0;
            break
          case '重要':
            this.attr.importance = 2;
            break
        }
        this.initViewList(this.attr,0);
      }
      this.status = key;
    },
    // 按地区筛选
    clickBtn2(key,event){
      this.loading = true;
      this.attr.importance = '';
      if(event.target.innerHTML.length>5){
        this.attr.country = (event.target.innerHTML.split('</')[0]).split('n>')[1];
      }else{
        if(event.target.innerHTML == '全部'){
          this.attr.country = '';
        }else{
          this.attr.country = event.target.innerHTML;
        }
      }
      this.local = key;
      this.initViewList(this.attr,0);
    },
    // 根据某一天日期，获取该日所在周的所有日期
    getWeek(input_day) {
      // 一天里一共的毫秒数
      let oneDayTime = 1000 * 60 * 60 * 24 
      let today = new Date(input_day)
      // 若那一天是周末时，则强制赋值为7
      let todayDay = today.getDay() || 7 
      let startDate = new Date(
        today.getTime() - oneDayTime * (todayDay - 1)
      )
      for(let i = 0; i < 7; i++){
        let temp = new Date(startDate.getTime() + i * oneDayTime)
        let year = temp.getFullYear();
        let month = temp.getMonth() + 1;
        if(month<10){
          month = '0'+month;
        }
        let day = temp.getDate();
        if(day<10){
          day = '0'+day;
        }
        this.weekDate[i] = `${year}-${month}-${day}` 
      }
      return this.weekDate;
    },
    // 根据日期判断与weekdata的第几个元素相等
    craigJudge(key){
      for(let i in this.weekDate){
        if(key == this.weekDate[i]){
          return i;
        }
      }
    },
    // 选择日期
    selectPicker(key){
      this.loading = true;
      this.attr.country = '';
      this.attr.importance = '';
      this.getWeek(key);
      this.controlDiv = Number(this.craigJudge(key));
      // 去掉key中的-
      this.attr.date = key.replace(/-/g,'');
      this.initViewList(this.attr,0);
    },
    // 获取更多数据
    moreCalendar(){
      this.loading = true;
      this.attr.date = this.datePicker.replace(/-/g,'');
      this.initViewList(this.attr,1);
    }
  }
}
</script>

<style lang="less" scoped>
  .calendar{
    width: 100%;
    .calTop{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 50px;
      /deep/ .el-input__suffix-inner{
        font-size: 22px;
        margin-right: 8px;
        .el-icon-date{
          color: #da3e4c;
        }
        .el-range__close-icon{
          display: none;
        }
      }
      .positionPic{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        i{
          color: #da3e4c;
          font-size: 24px;
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translate(-50%, -50%);
        }
      }
      .calTopTitle{
        font-size: 20px;
        color: #333333;
        margin-right: 40px;
      }
      .shaixuanBox{
        margin-left: 40px;
        position: relative;
        span{
          font-size: 20px;
          color: #333333;
          margin-right: 15px;
        }
        i{
          font-size: 22px;
          color: #da3e4c;
        }
        .shaixuanCenter{
          list-style: none;
          width: 510px;
          height: 550px;
          position: absolute;
          top: 10px;
          left: -57px;
          background:url(../../../static/market/juxing.png) no-repeat;
          background-size: cover;
          z-index: 999;
          display: none;
          box-sizing: border-box;
          padding: 50px 50px;
          li{
            font-size: 18px;
            font-weight: 550;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 10px;
            span{
              color: #333333;
              font-size: 18px;
              font-weight: 550;
            }
            p{
              color: #333333;
              font-size: 18px;
              font-weight: 550;
              margin-right: 10px;
            }
            .el-row{
              margin-bottom: 10px;
              .statusBtn{
                background-color: #db3d4c;
                border-color: #db3d4c;
              }
            }
          }
          li:nth-child(1){
            flex-direction: column;
            span{
              margin-bottom: 10px;
            }
          }
        }
      }
      .shaixuanBox:hover .shaixuanCenter{
        display: block;
      }
    }
    .inBoxLeft{
      width: 1416px;
      height: auto;
      box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
      margin-bottom: 80px;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
      .leftTop{
        width: 100%;
        height: 190px;
        background: url('../../../static/index/information1.png') no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        border-top-right-radius: 15px;
        p{
          color: #fffffd;
          margin-top: 10px;
          cursor: pointer;
        }
        ul{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          li{
            width: 225px;
            height: 80px;
            color: #fffffd;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            p{
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: relative;
              text-align: center;
              span:nth-child(1){
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                box-sizing: border-box;
                padding-top: 20px;
              }
              span:nth-child(2){
                position: absolute;
                bottom: 10px;
                left: 50%;
                transform:translate(-50%,-50%);
              }
            }
          }
          .liStyle{
            background-color: #fffffd;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            p{
              color: #333333;
            }
          }
        }
      }
      .leftBottom{
        width: 100%;
        // height: 545px;
        background-color: #ffffff;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        img{
          width: 50px;
          height: 50px;
        }
        .Influence{
          display: inline-block;
          width: 100px;
          height: 30px;
          line-height: 32px;
          border: 2px solid #333333;
        }
      }
      .button{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
          width: 195px;
          height: 60px;
          border-radius: 5px;
          background-color: #db3d4c;
          border: none;
          cursor: pointer;
          color: #ffffff;
          margin: 20px 0;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .calendar{
      width: 100%;
      .calTop{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        /deep/ .el-input__suffix-inner{
          font-size: 22px;
          margin-right: 8px;
          .el-icon-date{
            color: #da3e4c;
          }
          .el-range__close-icon{
            display: none;
          }
        }
        .calTopTitle{
          font-size: 14px;
          margin-right: 10px;
        }
        .positionPic{
          .el-date-editor{
            width: 200px !important;
          }
        }
        .shaixuanBox{
          margin-left: 10px;
          span{
            font-size: 14px;
            margin-right: 5px;
          }
          i{
            font-size: 14px;
          }
          .shaixuanCenter{
            width: 335px;
            height: auto;
            border-radius: 10px;
            top: 30px;
            left: auto;
            right: 0px;
            padding: 10px 10px;
            box-shadow:0px 0px 95px 0px rgba(43, 53, 105, 0.1);
            background: none;
            background-color: #ffffff;
            li{
              font-size: 14px;
              margin-bottom: 3px;
              span{
                font-size: 14px;
              }
              p{
                font-size: 14px;
              }
              .el-row{
                margin-bottom: 3px;
              }
            }
          }
        }
      }
      .inBoxLeft{
        width: 100%;
        .leftTop{
          height: 100px;
          ul{
            overflow-x: auto;
            white-space: nowrap;
            display: -webkit-box;
            li{
              width: 22%;
              height: 60px;
              p{
                font-size: 13px;
              }
            }
          }
          ul::-webkit-scrollbar {
            display: none;
          }
        }
        .leftBottom{
          /deep/.el-table{
            img{
              width: 30px;
              height: auto;
            }
            .el-table__row{
              height: 50px !important;
            }
            .cell .el-tooltip{
              font-size: 14px;
            }
          }
        }
        .button{
          button{
            width: 125px;
            height: 40px;
            border-radius: 5px;
            background-color: #db3d4c;
            border: none;
            cursor: pointer;
            color: #ffffff;
            margin: 20px 0;
            font-size: 14px;
          }
        }
      }
    }
  }
</style>